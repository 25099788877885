@import url("https://fonts.googleapis.com/css?family=Rubik&display=swap");

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
    --light-gray-colour: #eeeeee;
    --light-gray-border-colour: #d4d4d4;
    --dark-gray-colour: #545454;
    --black-colour: #111111;


    --default-font-family: "Rubik", sans-serif;
    --default-font-size: 16px;
    --default-line-height: 24px;

    --small_font_size: 14px;
    --medium_font_size: 18px;
    --large_font_size: 24px;
    --xlarge_font_size: 36px;

    --page_bgcolor: #236cc8;
    --page_color: #111111;

    --page_header_color: white;
    --page_header_bgcolor: #236cc8;
    --page_header_hover_color: #03a9f4;

    --page_footer_bgcolor: #0f2e57;
    --page_footer_color: #8da5c5;

    --link_color: #236cc8;
    --link_hover_color: #03a9f4;

    --icon_color: #236cc8;
    --icon_hover_color: #03a9f4;
    --del_icon_color: #FF0000;
    --field_border_radius: 0.25rem;

    --list-bgcolor: white;
    --list-color: #236cc8;
    --list_selected_bgcolor: white;
    --list_selected_color: #236cc8;

    --btn_default_color: white;
    --btn_default_bgcolor: #236cc8;
    --btn_default_hover_color: white;
    --btn_default_hover_bgcolor: #0a9ceb;

    --btn_cancel_color: white;
    --btn_cancel_bgcolor: #236cc8;
    --btn_cancel_hover_color: white;
    --btn_cancel_hover_bgcolor: #0a9ceb;

    --btn_other_color: white;
    --btn_other_bgcolor: #236cc8;
    --btn_other_hover_color: white;
    --btn_other_hover_bgcolor: #0a9ceb;

    --table_bgcolor: #ffffff;
    --table_color: var(--black-colour);
    --table_border_color: #236cc8;
    --table_header_bgcolor: #0a9ceb;
    --table_header_color: white;
    --table_selected_bgcolor: var(--light-gray-colour);
    --table_selected_color: var(--black-colour);
}

a {
    color: var(--link_color);
    text-decoration: none;
}

a:hover,
a:focus {
    color: var(--link_hover_color);
}

body {
    width: 100%;
    height: 100vh;
    font-family: var(--default-font-family);
    font-size: var(--default-font-size);
    font-weight: 400;
    line-height: var(--default-line-height);
    color: var(--black-colour);
    background-color: var(--light-gray-colour);
}

.header {
    position: relative;
    height: 2.5em;
    background-color: var(--page_header_bgcolor);
    color: var(--page_header_color);
    width: 100%;
    text-align: center;
    margin: 0 auto;
}

.footer {
    width: 100%;
    height: 2.5em;
    background-color: var(--page_footer_bgcolor);
    color: var(--page_footer_color);
}

.footer-container {
    width: 1280px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

.navbar-brand {
    min-width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 0.75rem;
    gap: 0.5em;
}
.navbar-logo {
    text-align: left;
    font-size: var(--large_font_size);
}
.logo-title {
    color: var(--page_header_color);
    font-size: var(--default_font_size);
    display: block;
}

.logo {
    padding: 0.25rem;
}

.container {
    width: 100%;
    min-height: calc(100vh - 5em);
    max-width: 1280px;
    margin: 0 auto;
    padding: 1rem 0.25rem;
    background-color: white;
}

.panel {
    width: 100%;
    margin: 1em auto;
    display: flex;
    background-color: white;
    border: 1px solid var(--light-gray-border-colour);
    border-radius: var(--field_border_radius);
}

.search-box {
    display: flex;
    width: 80%;
    gap: 1rem;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding: 0.5em;
}

.custom-dropdown{
  height: 1.75rem;
  outline: none;
  background-color: white;
  border-radius: var(--field_border_radius);
  border: 1px solid var(--light-gray-border-colour);
  font-size: var(--default-font-size);
  font-family: var(--default-font-family);
  padding-left: 0.25rem;
}

.date-picker-custom-input {
  display: flex;
  align-items: center;
  height: 35px;
  width: 120px;
  outline: none;
  padding: 10px;
  background-color: white;
  border-radius: var(--field_border_radius);
  border: 1px solid var(--light-gray-border-colour);
  font-size: var(--default-font-size);
  font-family: var(--default-font-family);
}

.btn {
  padding: 10px 20px;
  margin-bottom: 20px;
  border: none;
  border-radius: var(--field_border_radius);
  cursor: pointer;
}
.btn-with-icon {
    padding: 0.5em 1em;
    margin: unset;
    display: flex;
    gap: 1em;
}

.btn-default {
  background-color: var(--btn_default_bgcolor);
  font-size: var(--default-font-size);
  color: var(--btn_default_color);
}

.btn-default.selected,
.btn-default:hover {
  background-color: var(--btn_default_hover_bgcolor);
  color: var(--btn_default_hover_color);
}

.btn-default:disabled {
  background-color: var(--light-gray-colour);
  color: var(--light-gray-border-colour);
  pointer-events: none;
}

.data-grid {
    width: 100%;
    display: grid;
    color: var(--table_color);
    background-color: var(--table_bgcolor);
    text-align: left;
    grid-template-columns: 1.5fr 1fr 2fr 5.5fr 0.5fr 0.5fr; 
}
.data-grid-full-row{
    text-align: center;
    color: red;
    font-weight: 500;
}
.data-grid-row {
    width: 100%;
    line-height: 2rem;
    text-align: left;
    display: contents;
}

.data-grid-row>div {
    border-bottom: 1px solid var(--table_border_color);
}


.data-grid-row:first-child>div {
    background-color: var(--table_header_bgcolor);
    color: var(--table_header_color);
}

.data-grid-col {
    padding-left: 0.25em;
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

.data-grid-col svg{
    font-size: var(--large_font_size);
    color: var(--icon_color);
    cursor: pointer;
    padding-left: 0.25em;
}
.data-grid-col:hover svg{
    color: var(--icon_hover_color);
}

.data-grid-col.icon-disabled svg {
    color: var(--light-gray-border-colour);
    cursor: not-allowed;
}
.dialog-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.1);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dialog-box {
    min-width: 450px;
    min-height: 100px;
    max-width: 95%;
    max-height: 95%;
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid var(--light-gray-border-colour);
    border-radius: var(--field_border_radius);
    z-index: 99;
}
.dialog-heading {
    font-size: var(--medium-font-size);
    font-weight: 600;
    color: var(--page_header_color);
}
.dialog-title {
    background-color: var(--page_header_bgcolor);
    color: var(--page_header_color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 35px;
    padding: 0.25em 0.5em;
    border-top-right-radius: var(--field_border_radius);
    border-top-left-radius: var(--field_border_radius);
}
.dialog-title h2 {
    font-size: var(--medium-font-size);
    font-weight: 300;
    text-transform: uppercase;
}
.dialog-close {
    cursor: pointer;
    font-size: var(--large_font_size);
    padding-left: 1rem;
    padding-top: 0.25rem;
}
.dialog-body {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 1rem;
}
.dialog-footer {
    width: 100%;
    padding: 1em;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
}

.form-row {
    width: 100%;
    display: flex;
    gap: 0.5em;
    padding: 0.25em;
}
.form-input-field {
    width: 100%;
    height: 40px;
    background-color: var(--light-gray-colour);
    border: 1px solid var(--light-gray-border-colour);
    padding: 0.5em;
    border-radius: var(--field_border_radius);
    font-size: var(--default-font-size);
    font-family: var(--default-font-family);
}
.form-label {
    min-width: 5rem;
    font-size: var(--default-font-size);
    font-family: var(--default-font-family);
    height: 40px;
}
.form-textarea-field {
    width: 100%;
    height: 100px;
    resize: none;
    border: 1px solid var(--light-gray-border-colour);
    border-radius: var(--field_border_radius);
}

